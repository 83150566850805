import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Table, Button, Space } from 'antd'
import {
  ReservationCollectionListener,
  callOperaReservationCollectionSaveListenerApi,
  callOperaCloudReservationCollectionSaveListenerApi,
  unsubscribeReservations,
  sendEmailByEmailId,
} from '../../../../services/pms'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import {
  PaginationOptions,
  pendingLable,
  emailSentLable,
} from '../../../../config/constants'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import ConfirmationDialog from '../../../Common/ConfirmationDialog/ConfirmationDialog'
import crypto from 'crypto-js'

const generateEmailVariables = reservation => {
  const queryParams = `hotel=${encodeURIComponent(
    'our-hotel-resort-spa-dei'
  )}&uniqueId=${encodeURIComponent(reservation?.UniqueID)}`
  const encryptedParams = crypto.AES.encrypt(
    queryParams,
    'pms-precheckin-key'
  ).toString()
  return {
    '%name%': reservation?.guestName,
    '%link%': `https://dev-guest.inplass.online/prechecking?${encryptedParams}`,
    '%checkin%': `${reservation?.checkinDate}`,
    '%logo%': `gs://inplass-dev-new.appspot.com/profileImages/lin5qqpa.jpg`,
  }
}

const getEmailButtonProps = (emailAction) => {
  switch (emailAction) {
    case emailSentLable:
      return { text: 'Sent', disabled: true, showResend: true }
    case pendingLable:
      return { text: 'Send', disabled: false, showResend: false }
    default:
      return { text: 'Send', disabled: false, showResend: false }
  }
}

const PMSreserve = () => {
  const { hotelId, hotelInfo } = useContext(AuthContext)
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const reservations = useSelector(state => state.reservations)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedReservation, setSelectedReservation] = useState(null)
  const [email, setEmail] = useState('')
  const [variables, setVariables] = useState('')
  const [emailTemp, setEmailTemp] = useState('')
  const [processedReservations] = useState(new Set())
  const dispatch = useDispatch()

  const sendEmail = useCallback(async (reservation) => {
    if (processedReservations.has(reservation.UniqueID) && !selectedReservation) {
      return
    }

    setShowLoader(true)
    try {
      const variables = generateEmailVariables(reservation)
      const emailTemp = 'pre-checkin'
      const res = await sendEmailByEmailId(
        reservation.email,
        variables,
        emailTemp,
        hotelId
      )
      if (res.success) {
        console.log(`Email sent successfully to ${reservation.email}`)
        setSuccessMessage(res.message)
        if (!selectedReservation) {
          processedReservations.add(reservation.UniqueID)
        }
      } else {
        console.error(`Failed to send email to ${reservation.email}`)
        setErrorMessage(res.message)
      }
    } catch (error) {
      console.error('Error sending email:', error)
      setErrorMessage('Failed to send email')
    } finally {
      setShowLoader(false)
      setSelectedReservation(null)
    }
  }, [hotelId, processedReservations, selectedReservation])

  const handleResendEmail = (reservation) => {
    setSelectedReservation(reservation)
    setShowConfirmationDialog(true)
    setEmail(reservation.email)
    setVariables(generateEmailVariables(reservation))
    setEmailTemp('pre-checkin')
  }

  useEffect(() => {
    let isMounted = true
    const fetchReservations = async () => {
      if (!hotelId || !hotelInfo?.pmsType) return

      setShowLoader(true)
      try {
        const pmsType = hotelInfo?.pmsType
        if (pmsType === 'OPERA CLOUD') {
          await callOperaCloudReservationCollectionSaveListenerApi()
        } else if (pmsType === 'OPERA OWS') {
          await callOperaReservationCollectionSaveListenerApi(hotelInfo)
        }

        // Fetching the Reservation data from DB
        if (isMounted) {
          await ReservationCollectionListener(hotelId, pmsType, dispatch)
        }
      } catch (error) {
        console.error(error)
        if (isMounted) {
          setErrorMessage('Failed to fetch reservations.')
        }
      } finally {
        if (isMounted) {
          setShowLoader(false)
        }
      }
    }

    fetchReservations()

    return () => {
      isMounted = false
      unsubscribeReservations(hotelId)
    }
  }, [hotelId, dispatch, hotelInfo]) // Remove reservations from dependencies

  // Separate effect for processing pending reservations
  useEffect(() => {
    const processPendingReservations = async () => {
      for (const reservation of reservations) {
        if (reservation.emailAction === pendingLable) {
          await sendEmail(reservation)
        }
      }
    }

    if (reservations.length > 0) {
      processPendingReservations()
    }
  }, [reservations, sendEmail])

  const columns = [
    {
      title: 'Sl.No',
      dataIndex: 'index',
      width: 100,
      key: 'index',
      render: (_, __, index) => index + 1,
    },
    {
      title: translateTextI18N('Reservation Id'),
      dataIndex: 'reservationId',
      render: (text, row) => `${row.UniqueID}`,
      width: 150,
    },
    {
      title: translateTextI18N('Booking Reference'),
      dataIndex: 'bookingReference',
      render: (text, row) => `${row.bookingRefrence}`,
      width: 150,
    },
    {
      title: translateTextI18N('Guest Name'),
      dataIndex: 'guestName',
      render: (text, row) => `${row.guestName}`,
      width: 200,
    },
    {
      title: translateTextI18N('Email'),
      dataIndex: 'email',
      render: (text, row) => `${row.email}`,
      width: 220,
    },
    {
      title: translateTextI18N('Arrival Date'),
      dataIndex: 'checkinDate',
      render: (text, row) => `${row.checkinDate}`,
      width: 150,
    },
    {
      title: translateTextI18N('Status'),
      dataIndex: 'status',
      render: (text, row) => `${row.status}`,
      width: 150,
    },
    {
      title: translateTextI18N('Action'),
      dataIndex: 'emailAction',
      width: 150,
      render: (_, row) => {
        const { text, disabled, showResend } = getEmailButtonProps(
          row.emailAction
        )
        return (
          <Space>
            {showResend && (
              <Button
              className={`statusBtn completedBtn`}
                onClick={() => handleResendEmail(row)}
              >
                {translateTextI18N('Resend')}
              </Button>
            )}
          </Space>
        )
      },
    },
  ]

  const handleConfirmationDialogOk = async () => {
    setShowConfirmationDialog(false)
    if (selectedReservation) {
      await sendEmail(selectedReservation)
    } else {
      try {
        const res = await sendEmailByEmailId(
          email,
          variables,
          emailTemp,
          hotelId
        )
        if (res.success) {
          setSuccessMessage(res.message)
        } else {
          setErrorMessage(res.message)
        }
      } catch (error) {
        console.error('Error sending email:', error)
        setErrorMessage('Failed to send email')
      }
    }
    setShowLoader(false)
  }

  const handleConfirmationDialogCancel = () => {
    setShowConfirmationDialog(false)
    setShowLoader(false)
    setSelectedReservation(null)
  }

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Reservations'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'Reservations',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={!!successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                />
                <CustomAlert
                  visible={!!errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={columns}
                      dataSource={reservations}
                      loading={showLoader}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationDialog
        visible={showConfirmationDialog}
        onCancelClick={handleConfirmationDialogCancel}
        onOkClick={handleConfirmationDialogOk}
        title={`Send Pre-checkin Email`}
        message={`Are you sure you want to send pre-checkin email to the guest`}
        okButtonText='Confirm'
      />
    </>
  )
}

export default PMSreserve
