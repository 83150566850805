import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Table, Button, message, Modal, Select, Form } from 'antd'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import {
  AcceptRejectCheckIn,
  AddGuestListener,
} from '../../../../services/guest'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import { GetCurrentUser } from '../../../../services/user'
import {
  PaginationOptions,
  pendingLable,
  rejectedLable,
  secondsToShowAlert,
} from '../../../../config/constants'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import ConfirmationDialog from '../../../Common/ConfirmationDialog/ConfirmationDialog'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AdminRequest } from '../../../../services/notification'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import { getCommonColumns, sanitiseImages } from '../../../../config/utils'
import {
  sendEmail,
  acceptRejectPmsCheckin,
  preCheckinRequestCollectionListener,
  unsubscribePreCheckinRequests,
} from '../../../../services/pms'
import MenuGallery from '../../Restaurant/MenuGallery'
const GetButtonTextAndClass = row => {
  const disabled = row.status !== pendingLable ? 'disabled' : ''
  let acceptButtonText = 'ACCEPT'
  let rejectButtonText = 'REJECT'
  if (row.status !== pendingLable) {
    acceptButtonText = ''
    rejectButtonText = ''
    if (row.status === rejectedLable) {
      rejectButtonText = 'REJECTED'
    } else {
      acceptButtonText = 'ACCEPTED'
    }
  }
  return { disabled, acceptButtonText, rejectButtonText }
}
const PMScheckin = () => {
  const { hotelId, userInfo } = useContext(AuthContext)
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [requestId, setRequestId] = useState(false)
  const [requestRoomNumber, setRequestRoomNumber] = useState('')
  const [email, setEmail] = useState('')
  const [requestReferenceId, setReferenceId] = useState('')
  const [accepted, setAccepted] = useState(false)
  const [confirmationTitle, setConfirmationTitle] = useState('')
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [guestId, setGuestId] = useState('')
  const preCheckinRequestsData = useSelector(state => state.preCheckinRequests)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const { checkInCheckOutRequests, loadingGuests } = useSelector(state => state)
  const [isImageViewClicked, setIsImageViewClicked] = useState(false)
  const { hotelInfo } = useSelector(state => state)
  const dispatch = useDispatch()
  const handleGalleryClick = () => {
    setIsImageViewClicked(true)
    setTimeout(() => {
      setIsImageViewClicked(false)
    }, 5000)
  }
  useEffect(() => {
    const fetchPreCheckinRequests = async () => {
      setShowLoader(true)
      try {
        // need to pass pms type so we can laod the requests based upon pms type.
        const pmsType = hotelInfo?.pmsType
        await preCheckinRequestCollectionListener(hotelId, pmsType, dispatch)
      } catch (error) {
        setErrorMessage('Failed to fetch Requests.')
      } finally {
        setShowLoader(false)
      }
    }
    fetchPreCheckinRequests()
    return () => {
      unsubscribePreCheckinRequests(hotelId)
    }
  }, [hotelId, dispatch])
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  useEffect(() => {
    AddGuestListener(hotelId, dispatch)
  }, [dispatch, hotelId])
  const setMessage = (setMessageFunc, messageText) => {
    setMessageFunc(translateTextI18N(messageText ?? ''))
    setTimeout(() => setMessageFunc(''), secondsToShowAlert)
  }
  const acceptRejctClick = async (
    id,
    roomNumber,
    email,
    referenceId,
    action,
    title,
    messageText
  ) => {

    console.log('id', id)
    setRequestId(id)
    setRequestRoomNumber(roomNumber)
    setEmail(email)
    setReferenceId(referenceId)
    setAccepted(action)
    setConfirmationTitle(title)
    setConfirmationMessage(messageText)
    setShowConfirmationDialog(true)
    // callCheckIn()
  }
  const acceptRejectCheckIn = async () => {
    try {
      if (showLoader) return
      setShowConfirmationDialog(false)
      setShowLoader(true)
      const { success, message: acceptCheckInMessage } =
        await acceptRejectPmsCheckin(
          hotelId,
          requestId,
          accepted,
          email,
          preCheckinRequestsData,
          hotelInfo
        )
      if (success) {
        let status
        let templateName
        if (accepted === true) {
          templateName = 'ACCEPTED_CHECKIN_STATUS'
          status = 'accepted'
        } else {
          templateName = 'REJECTED_CHECKIN_STATUS'
          status = 'rejected'
        }
        const userid = GetCurrentUser().uid
        let template_variables = { '%status%': status }
        AdminRequest(
          templateName,
          template_variables,
          userid,
          requestId,
          hotelId,
          requestReferenceId,
          null,
          userInfo.departmentId
        )
        setMessage(
          setSuccessMessage,
          `Guest check in ${accepted ? 'accepted successfully' : 'rejected'} `
        )
        const emailTemp = 'autoCheckin'
        // sendEmail(preCheckinRequestsData, requestId, emailTemp)
      } else {
        setMessage(setErrorMessage, acceptCheckInMessage)
      }
    } catch (error) {
      message.error(error.message || 'Something went wrong! Please try again!')
    } finally {
      setShowLoader(false)
    }
  }
  const {
    // guestFullName,
    bookingReferanceCol,
    submittedTimeCol,
  } = getCommonColumns({
    translateTextI18N,
  })
  bookingReferanceCol.width = 130
  // guestFullName.width = 170
  const frontDeskColumns = [
    {
      title: 'Sl.No',
      dataIndex: 'index',
      width: 90,
      key: 'index',
      render: (_, __, index) => index + 1,
    },
    {
      title: translateTextI18N('Guest Name'),
      dataIndex: 'guestName',
      width: 170,
      
    },
    bookingReferanceCol,
    {
      title: translateTextI18N('Id Number'),
      dataIndex: 'verificationIdNumber',
      width: 100,
    },
    {
      title: translateTextI18N('Id Name'),
      dataIndex: 'verificationName',
      width: 100,
    },
    {
      title: translateTextI18N('Check-In Date'),
      dataIndex: 'checkin',
      width: 100,
    },
    {
      title: translateTextI18N('Check-In Time'),
      dataIndex: 'arrival',
      width: 100,
    },
    {
      title: translateTextI18N('Accompanying Guest'),
      dataIndex: 'additionalGuestName',
      width: 170,
    },
    submittedTimeCol,
    {
      title: translateTextI18N('Status'),
      dataIndex: 'Status',
      className: '',
      width: 240,
      render: (_, row) => {
        const { disabled, acceptButtonText, rejectButtonText } =
          GetButtonTextAndClass(row)
        const { guestId } = row
        return (
          <div>
            <Button
              className={`statusBtn completedBtn ${disabled}`}
              onClick={() => {
                if (!disabled) {
                    acceptRejctClick(
                      row.id,
                      row.roomNumber,
                      row.email,
                      row.bookingReferance,
                      true,
                      'Accept Guest',
                      `Are you sure you want to accept the guest`
                    )
                }
              }}
            >
              {translateTextI18N(acceptButtonText)}
            </Button>
            <Button
              className={`statusBtn rejectBtn ml-2 ${disabled}`}
              onClick={() => {
                if (!disabled) {
                  acceptRejctClick(
                    row.id,
                    row.roomNumber,
                    row.email,
                    row.bookingReferance,
                    false,
                    'Reject Guest',
                    `Are you sure you want to reject this request ?`
                  )
                }
              }}
            >
              {translateTextI18N(rejectButtonText)}
            </Button>
          </div>
        )
      },
    },
  ]
  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Pre Check-In'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'Pre Check-In',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                />
                <CustomAlert
                  visible={errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      //need to add arrival time of guest in  the coloumns and add serial number.
                      columns={frontDeskColumns}
                      dataSource={preCheckinRequestsData}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      loading={showLoader || loadingGuests}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmationDialog
        visible={showConfirmationDialog}
        onCancelClick={() => setShowConfirmationDialog(false)}
        onOkClick={acceptRejectCheckIn}
        title={confirmationTitle}
        message={confirmationMessage}
        okButtonText='Confirm'
      />
    </>
  )
}
export default PMScheckin